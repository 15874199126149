@import './Util/allUtils';

.education-card {
    & .institution-container{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: spacing(2);

        & > img {
            width: 56px;
            height: 56px;
            border-radius: 12px;
            margin-right: spacing(2);
        }
    }

    & .body{
        &-container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        }
        &-row {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
        }
    }

    & .data{
        &-group{
            display: flex;
            flex-flow: column nowrap;
            width: calc(100% - spacing(10));
        }

        &-label{
            min-width: 80px;
        }
        &-value {
            flex-grow: 1;
        }
    }
}