@import "./Util/allUtils";


.home-brain-group{
    position: fixed;
    top: 464px;
    left: calc((((100vw - 344px) / 12) * 6) + 184px);
    overflow: visible;
    display: flex;
    z-index: -400;
    height: 554px;
    width: 554px;
    display: flex;
    justify-content: center;
    transition: $standard-transition;

    @media (nmcdbp(medium, only)) {
        top: 616px;
        left: calc((((100vw - 344px) / 12) * 3) + 112px);
        height: 560px;
        width: 560px;
    }

    @media (nmcdbp(small, only)) {
        display: none;
        // position: relative;
        // top: spacing(8) !important;
        // left: calc((100vw - 500px) / 2) !important;
        // height: 500px !important;
        // width: 500px !important;
    }

    &.is-apart{
        // width: 100% !important;
        
        & .home-brain-left, & .home-brain-right{
            width: calc((100vw - 344px) / 12);

            @media (nmcdbp(medium, only)) {
                width: calc(((100vw - 312px) / 12) + spacing(3));
            }
        }
        & .home-brain-gap{
            width: 100%;

            @media (nmcdbp(small, only)) {
                width: 500px;
            }
        }
    }
}

.home-brain-left{
    transition: all 500ms ease-in-out;
    height: 100%;
    width: 50%;
    margin-right: -2px;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: $long-transition;
}

.home-brain-right{
    transition: all 500ms ease-in-out;
    height: 100%;
    width: 50%;
    margin-left: -2px;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: $long-transition;
}

.brain-img{
    height: 100%;
}

.home-brain-gap{
    width: 0;
    height: 100%;
    transition: $long-transition;
}