@import './Util/allUtils';

.alert-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: calc(100% - spacing(6));
    border-radius: 24px;
    padding: spacing(3);

    &.alert-global{
        border-radius: 0;
        padding: spacing(3) spacing(5);
        width: calc(100% - spacing(10));
        position: fixed;
        top: 0;
        z-index: 1000;
        @media (nmcdbp(medium, down)) {
            padding: spacing(3) spacing(3);
            width: calc(100% - spacing(6));
        }
    }

    &.alert-{
        &success{
            background-color: $green-100;
            & .alert-content-container p, & .alert-content-container i {
                color: $green-800;
            }
        }
        &warning{
            background-color: $yellow-100;
            & .alert-content-container p, & .alert-content-container i {
                color: $yellow-800;
            }
        }
        &info{
            background-color: $blue-100;
            & .alert-content-container p, & .alert-content-container i {
                color: $blue-800;
            }
        }
        &error{
            background-color: $red-100;
            & .alert-content-container p, & .alert-content-container i {
                color: $red-800;
            }
        }
    }

    &.alert-is-dismissable{
        width: calc(100% - spacing(4.5));
        padding: spacing(1.5) spacing(1.5) spacing(1.5) spacing(3);
        &.alert-global{
            padding: spacing(1.5) spacing(1.5) spacing(1.5) spacing(5);
            width: calc(100% - spacing(6.5));
            @media (nmcdbp(medium, down)) {
                padding: spacing(1.5) spacing(1.5) spacing(1.5) spacing(3);
                width: calc(100% - spacing(4.5));
            }
        }
    }
}

.alert-content-container{
    display: flex;
    align-items: center;
    & > i{
        font-size: fontsizing(18);
        line-height: fontsizing(24);
        margin-right: spacing(1);
    }
}

.dark-mode.alert-container.alert-{
    &success{
        background-color: $green-800;
        & .alert-content-container p, & .alert-content-container i {
            color: $green-100;
        }
    }
    &warning{
        background-color: $yellow-800;
        & .alert-content-container p, & .alert-content-container i {
            color: $yellow-100;
        }
    }
    &info{
        background-color: $blue-800;
        & .alert-content-container p, & .alert-content-container i {
            color: $blue-100;
        }
    }
    &error{
        background-color: $red-800;
        & .alert-content-container p, & .alert-content-container i {
            color: $red-100;
        }
    }
}

.project-page-alert{
    margin-top: spacing(12);
    margin-bottom: spacing(-9);
}