@import './Util/allUtils';

.settings-form{
    & .button-group{
        margin-top: spacing(4);
    }
}

.settings-content-radio-group{
    margin-top: spacing(3);
}