@import './Util/allUtils';

.image{
    &-grid-container{
        width: calc(100% + spacing(3));
        display: flex;
        flex-flow: row wrap;
        margin-left: spacing(-1.5);
        margin-right: spacing(-1.5);
        margin-bottom: spacing(-3);
        @media (nmcdbp(small, only)) {
            width: calc(100% + spacing(2));
            margin-left: spacing(-1);
            margin-right: spacing(-1);
            margin-bottom: spacing(-2);
        }

        & img {
            border-radius: 16px;
            width: 100%;
            // max-height: 100%;
            transition: $standard-transition;
        }
    }
    &-modal-grid{
        //display: flex;
        border-radius: 16px;
        overflow: hidden;
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
        margin-bottom: spacing(3);
        width: calc((100% - 48px) / 2 - 0px);
        max-height: calc((((100vw - 344px) / 12) * 2) + 24px);
    }
    &-small{
        //display: flex;
        border-radius: 16px;
        overflow: hidden;
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
        margin-bottom: spacing(3);
        width: calc((((100vw - 344px) / 12) * 2) + spacing(2.5));
        max-height: calc((((100vw - 344px) / 12) * 2) + spacing(2.5));

        @media (nmcdbp(medium, only)) {
            width: calc((((100vw - 312px) / 12) * 3) + spacing(5.5) - 1px);
            max-height: calc((((100vw - 312px) / 12) * 3) + spacing(5.5) - 1px);
        }
        @media (nmcdbp(small, only)) {
            margin-left: spacing(1);
            margin-right: spacing(1);
            margin-bottom: spacing(2);
            width: calc((((100vw - 96px) / 4) * 2) + spacing(.9));
            max-height: calc((((100vw - 96px) / 4) * 2) + spacing(.9));
        }        
    }

    &-medium{
        //display: flex;
        border-radius: 16px;
        overflow: hidden;
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
        margin-bottom: spacing(3);
        width: calc((((100vw - 344px) / 12) * 3) + spacing(5.25));
        max-height: calc((((100vw - 344px) / 12) * 3) + spacing(5.25));

        @media (nmcdbp(medium, only)) {
            width: calc((((100vw - 312px) / 12) * 4) + spacing(8.25));
            max-height: calc((((100vw - 312px) / 12) * 4) + spacing(8.25));
        }
        @media (nmcdbp(small, only)) {
            margin-left: spacing(1);
            margin-right: spacing(1);
            margin-bottom: spacing(2);
            width: calc((((100vw - 96px) / 4) * 2) + spacing(.9));
            max-height: calc((((100vw - 96px) / 4) * 2) + spacing(.9));
        }        
    }

    &-large{
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
        margin-bottom: spacing(3);
        width: calc((((100vw - 344px) / 12) * 4) + spacing(8));
        max-height: calc((((100vw - 344px) / 12) * 4) + spacing(8));

        @media (nmcdbp(medium, only)) {
            width: calc((((100vw - 312px) / 12) * 4) + spacing(8.25));
            max-height: calc((((100vw - 312px) / 12) * 4) + spacing(8.25));
        }
        @media (nmcdbp(small, only)) {
            margin-left: spacing(1);
            margin-right: spacing(1);
            margin-bottom: spacing(2);
            width: calc(100vw - 48px);
            max-height: calc(100vw - 48px);
        }        
    }
}

.image-modal-grid.is-selected{
    position: relative;

    & img{
        transform: scale(.8);
    }
    &::before{
        content: '\F12F';
        width: 32px;
        height: 32px;
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        z-index: 2;
        font-family: "bootstrap-icons" !important;
        font-size: fontsizing(32);
        line-height: fontsizing(32);
        color: $interactiveColor-500;
        opacity: 1;
        transition: $standard-transition;
    }

    &::after{
        content: '';
        @include frosted-3;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition: $standard-transition;
    }
}

.img-is-clickable{
    position: relative;
    cursor: zoom-in;

    &::before{
        width: 32px;
        height: 32px;
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        z-index: 2;
        font-family: "bootstrap-icons" !important;
        font-size: fontsizing(32);
        line-height: fontsizing(32);
        color: $interactiveColor-500;
        opacity: 0;
        transition: $standard-transition;
    }

    &::after{
        content: '';
        @include frosted-3;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: $standard-transition;
    }


    &:hover {
        & img{
            transform: scale(.8);
        }

        &::before{
            content: '\F62C';
            opacity: 1;
        }

        &::after{
            opacity: 1;
        }
    }
}

.dark-mode {
    & .image-modal-grid.is-selected{
        &::before{
            color: $interactiveColor-200;
        }
    
        &::after{
            content: '';
            @include frosted-3-dark;
        }
    }

    & .img-is-clickable{
        &::before{
            color: $interactiveColor-200;
        }
    
        &::after{
            @include frosted-3-dark;
        }
    }
}