@import './Util/allUtils';

.home-project-block{
    display: flex;
    align-items: center;
    min-height: 100vh;

    @media (nmcdbp(small, only)) {
        min-height: auto;
        margin-top: spacing(10);
    }
}

.see-more {
    &-home {
        height: 100vw;
        //min-height: 400px;
        max-height: 1024px;
        justify-content: center;
        align-items: center;

        @media (nmcdbp(small, only)) {
            //align-items: flex-start;
        }

        & .see-more-frame {
            padding: spacing(4) spacing(3);
            border-radius: 40px;

            @include frosted-1;

            @media (nmcdbp(small, only)) {
                margin-top: spacing(10);
                margin-bottom: spacing(10);
            }

            & h2 {
                text-align: center;
            }
    
            & .button-group{
                justify-content: center;
                align-items: center;

                @media (nmcdbp(small, only)) {
                    align-items: stretch;
                }
            }
        }
        
    }
}
.dark-mode .see-more-home .see-more-frame{
    @include frosted-1-dark;
}

.home-about-me{
    @include frosted-1;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    padding-bottom: spacing(7);

    @media (nmcdbp(medium, down)) {
        padding-left: 24px;
        padding-right: 24px;
        margin-left: -24px;
        margin-right: -24px;
    }

    &-recs {
        @media (nmcdbp(medium, down)) {
            margin-top: spacing(6);
        }
    }
}

.dark-mode .home-about-me{
    @include frosted-1-dark;
}

.home-headshot {
    width: 100%;
    border-radius: 32px;
}