@import './Util/allUtils';

.loader-small, .loader-small:before, .loader-small:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader-small {
  color: #FFF;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &.loader-neutral-100 {
    color: $neutral-100;
  }
}
.loader-small:before,
.loader-small:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader-small:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader-small:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}
    