@import './Util/allUtils';

.buttonBase {
    font-size: fontsizing(18);
    line-height: fontsizing(24);
    font-family: $body-font-family;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    min-height: 48px;
    justify-content: center;

    &.is-loading {
        position: relative;

        & .loader-small {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.button-filled {
    @extend .buttonBase;
    border-radius: 16px;
    padding: spacing(1.5) spacing(2);
    border: 0 solid;
    background-color: $interactiveColor-700;
    color: $neutral-100;
    outline: 0 $interactiveColor-200 solid;
    transition: $standard-transition;

    &:hover, &:focus {
        background-color: $interactiveColor-800;
        outline-width: 2px;
    }

    &:active {
        background-color: $interactiveColor-900;
        outline-width: 0;
    }
    
    &.has-icon {
        i {
            font-size: fontsizing(18);
            line-height: fontsizing(18);
            align-self: center;
            padding-left: spacing(1);
        }
    }

    &.icon-only{
        padding: spacing(1.5) spacing(1.5);
        i {
            font-size: fontsizing(24);
            line-height: fontsizing(24);
            align-self: center;
        }
    }

    &.is-loading{
        color: $interactiveColor-300;
        background-color: $interactiveColor-800;
        outline-width: 0;
        cursor: wait;
    }
}

.dark-mode .button-filled{
    background-color: $interactiveColor-400;
    color: $neutral-900;
    outline-color: $interactiveColor-800;

    &:hover, &:focus {
        background-color: $interactiveColor-300;
    }

    &:active {
        background-color: $interactiveColor-200;
    }
}

.button-outline {
    @extend .buttonBase;
    border-radius: 16px;
    padding: spacing(1.5) spacing(2);
    border: 1px $interactiveColor-700 solid;
    background-color: transparent;
    color: $interactiveColor-700;
    outline: 0 $interactiveColor-200 solid;
    transition: $standard-transition;

    &:hover, &:focus {
        color: $interactiveColor-800;
        border-color: $interactiveColor-800;
        background-color: $interactiveColor-100;
        outline-width: 2px;
    }

    &:active {
        color: $interactiveColor-800;
        border-color: $interactiveColor-800;
        background-color: $interactiveColor-200;
        outline-width: 0;
    }
    
    &.has-icon {
        i {
            font-size: fontsizing(18);
            line-height: fontsizing(18);
            align-self: center;
            padding-left: spacing(1);
        }
    }

    &.icon-only{
        padding: spacing(1.5) spacing(1.5);
        i {
            font-size: fontsizing(24);
            line-height: fontsizing(24);
            align-self: center;
        }
    }

    &:disabled {
        color: $neutral-300;
        border-color: $neutral-300;

        &:hover, &:focus, &:active {
            color: $neutral-300;
            border-color: $neutral-300;
            background-color: transparent;
            outline-width: 0;
            cursor: not-allowed;
        }
    }
}

.dark-mode .button-outline{
    border-color: $interactiveColor-400;
    color: $interactiveColor-400;
    outline-color: $interactiveColor-800;

    &:hover, &:focus {
        color: $interactiveColor-500;
        border-color: $interactiveColor-500;
        background-color: $interactiveColor-900;
    }

    &:active {
        color: $interactiveColor-500;
        border-color: $interactiveColor-500;
        background-color: $interactiveColor-800;
    }

    &:disabled {
        color: $neutral-600;
        border-color: $neutral-600;

        &:hover, &:focus, &:active {
            color: $neutral-600;
            border-color: $neutral-600;
            background-color: transparent;
            outline-width: 0;
            cursor: not-allowed;
        }
    }
}

.button-hollow {
    @extend .buttonBase;
    border-radius: 16px;
    padding: spacing(1.5) spacing(2);
    border: 0;
    background-color: transparent;
    color: $neutral-900;
    outline: 0 $interactiveColor-200 solid;
    transition: $standard-transition;

    &:hover, &:focus {
        color: $interactiveColor-800;
        background-color: $interactiveColor-100;
        outline-width: 2px;
    }

    &:active {
        color: $interactiveColor-800;
        background-color: $interactiveColor-200;
        outline-width: 0;
    }
    
    &.has-icon {
        i {
            font-size: fontsizing(18);
            line-height: fontsizing(18);
            align-self: center;
            padding-left: spacing(1);
        }
    }

    &.icon-only{
        padding: spacing(1.5) spacing(1.5);
        i {
            font-size: fontsizing(24);
            line-height: fontsizing(24);
            align-self: center;
        }
    }
}

.dark-mode .button-hollow{
    color: $neutral-100;
    outline-color: $interactiveColor-800;

    &:hover, &:focus {
        color: $interactiveColor-500;
        background-color: $interactiveColor-900;
    }

    &:active {
        color: $interactiveColor-500;
        background-color: $interactiveColor-800;
    }
}

.button-hollow-square {
    @extend .buttonBase;
    padding: spacing(1.5) spacing(2);
    border: 0;
    background-color: transparent;
    color: $neutral-900;
    transition: $standard-transition;

    &:hover, &:focus {
        color: $interactiveColor-800;
        background-color: $interactiveColor-100;
    }

    &:active {
        color: $interactiveColor-800;
        background-color: $interactiveColor-200;
    }
    
    &.has-icon {
        i {
            font-size: fontsizing(18);
            line-height: fontsizing(18);
            align-self: center;
            padding-left: spacing(1);
        }
    }

    &.icon-only{
        padding: spacing(1.5) spacing(1.5);
        i {
            font-size: fontsizing(24);
            line-height: fontsizing(24);
            align-self: center;
        }
    }
}

.dark-mode .button-hollow-square{
    color: $neutral-100;
    outline-color: $interactiveColor-800;

    &:hover, &:focus {
        color: $interactiveColor-500;
        background-color: $interactiveColor-900;
    }

    &:active {
        color: $interactiveColor-500;
        background-color: $interactiveColor-800;
    }
}

.stretch-at-small {
    @media (nmcdbp(small, only)) {
        width: 100%;
    }
}

.button-group {
    display: flex;
    flex-flow: row wrap;

    & :not(:first-child){
        margin-left: spacing(2);
    }

    @media (nmcdbp(small, only)) {
        flex-flow: column nowrap;

        & :not(:first-child){
            margin-left: 0;
            margin-top: spacing(2);
        }
    }
}

.button-hollow-square.button-color, .button-hollow.button-color{
    &-red{
        color: $red-800;
        outline-color: $red-200;

        &:hover, &:focus {
            color: $red-800;
            background-color: $red-100;
        }

        &:active {
            color: $red-900;
            background-color: $red-200;
        }
    }
    &-orange{
        color: $orange-800;
        outline-color: $orange-200;

        &:hover, &:focus {
            color: $orange-800;
            background-color: $orange-100;
        }

        &:active {
            color: $orange-900;
            background-color: $orange-200;
        }
    }
    &-yellow{
        color: $yellow-800;
        outline-color: $yellow-200;

        &:hover, &:focus {
            color: $yellow-800;
            background-color: $yellow-100;
        }

        &:active {
            color: $yellow-900;
            background-color: $yellow-200;
        }
    }
    &-green{
        color: $green-800;
        outline-color: $green-200;

        &:hover, &:focus {
            color: $green-800;
            background-color: $green-100;
        }

        &:active {
            color: $green-900;
            background-color: $green-200;
        }
    }
    &-blue{
        color: $blue-800;
        outline-color: $blue-200;

        &:hover, &:focus {
            color: $blue-800;
            background-color: $blue-100;
        }

        &:active {
            color: $blue-900;
            background-color: $blue-200;
        }
    }
    &-purple{
        color: $purple-800;
        outline-color: $purple-200;

        &:hover, &:focus {
            color: $purple-800;
            background-color: $purple-100;
        }

        &:active {
            color: $purple-900;
            background-color: $purple-200;
        }
    }
}

.dark-mode .button-hollow-square.button-color, .dark-mode .button-hollow.button-color{
    &-red{
        color: $red-100;
        outline-color: $red-800;

        &:hover, &:focus {
            color: $red-100;
            background-color: $red-900;
        }

        &:active {
            color: $red-200;
            background-color: $red-800;
        }
    }
    &-orange{
        color: $orange-100;
        outline-color: $orange-800;

        &:hover, &:focus {
            color: $orange-100;
            background-color: $orange-900;
        }

        &:active {
            color: $orange-200;
            background-color: $orange-800;
        }
    }
    &-yellow{
        color: $yellow-100;
        outline-color: $yellow-800;

        &:hover, &:focus {
            color: $yellow-100;
            background-color: $yellow-900;
        }

        &:active {
            color: $yellow-200;
            background-color: $yellow-800;
        }
    }
    &-green{
        color: $green-100;
        outline-color: $green-800;

        &:hover, &:focus {
            color: $green-100;
            background-color: $green-900;
        }

        &:active {
            color: $green-200;
            background-color: $green-800;
        }
    }
    &-blue{
        color: $blue-100;
        outline-color: $blue-800;

        &:hover, &:focus {
            color: $blue-100;
            background-color: $blue-900;
        }

        &:active {
            color: $blue-200;
            background-color: $blue-800;
        }
    }
    &-purple{
        color: $purple-100;
        outline-color: $purple-800;

        &:hover, &:focus {
            color: $purple-100;
            background-color: $purple-900;
        }

        &:active {
            color: $purple-200;
            background-color: $purple-800;
        }
    }
}