@import 'Util/allUtils';

.nmcd-site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 900;
}

.header-bkg {
    height: 56px;
    width: 100vw;
    background-color: rgba($neutral-100, .8);
    position: absolute;
    top: -56px;
    left: 0;
    z-index: -1;
    transition: top 200ms linear;
    backdrop-filter: blur(24px);
}

.header-content-container {
    padding-left: spacing(5);
    padding-right: spacing(5);
    width: calc(100% - spacing(10));
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @media (nmcdbp(medium, down)) {
        flex-flow: wrap;
        padding-left: spacing(3);
        padding-right: spacing(3);
        width: calc(100% - spacing(6));
    }
}

.logoitem-container{
    z-index: 1;
    @media (nmcdbp(medium, down)) {
        width: 50%;
    }
}

.navitems-container {
    display: flex;
    flex-flow: row nowrap;

    & > :not(:first-child) {
        margin-left: spacing(1);
    }

    @media (nmcdbp(medium, down)) {
        flex-flow: column;
        background-color: rgba($neutral-100, .85);
        backdrop-filter: blur(24px);
        width: 100%;
        height: 0;
        align-items: start;
        margin: spacing(-8) spacing(-3) 0;
        padding: spacing(8) spacing(3) 0;
        transform: translateX(105vw);
        opacity: 0;
        transition: $long-transition;

        &.is-open {
            height: calc(100vh - spacing(8));
            opacity: 1;
            transform: translateX(0);
        }

        & > :not(:first-child) {
            margin-left: 0;
            margin-top: spacing(1);
        }

        & > :first-child {
            margin-top: spacing(6);
        }
    }
}

.menu-button-container{
    display: flex;
    justify-content: end;
    z-index: 1;
    @media (nmcdbp(medium, down)) {
        width: 50%;
    }
}

.menu-button {
    border: 0;
    min-width: 48px;
    min-height: 48px;
    color: $neutral-900;
    background-color: transparent;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (nmcdbp(large, up)) {
        display: none;
    }

    & > div {
        transition: $standard-transition;
        width: 18px;
        height: 1.5px;
        border-radius: 1px;
        background-color: $neutral-900;
        &:nth-child(1){
            margin: 0 0;
        } 
        &:nth-child(2){
            margin: 4.5px 0;
        }
        &:nth-child(3) {
            margin: 0 0;
        }
    }

    &:hover, &:focus, &:focus-visible {
        & > div {
            background-color: transparent;
            background: $gradient-500;
            &:nth-child(2){
                margin: 6.5px 0;
            }
        }
    }

    &:active {
        & > div {
            background-color: $neutral-900;
            background: $neutral-900;
            &:nth-child(2){
                margin: 2.5px 0;
            }
        }
    }

    &.close {
        & > div {
            &:nth-child(1){
                transform: rotate(45deg);
                margin-bottom: -.75px;
            }
            &:nth-child(2){
                opacity: 0;
                margin: 0;
                height: 0;
            }
            &:nth-child(3){
                transform: rotate(-45deg);
                margin-top: -.75px;
            }
        }

        &:hover, &:focus, &:focus-visible {
            & > div {
                &:nth-child(1){
                    transform: rotate(35deg);
                }
                &:nth-child(3){
                    transform: rotate(-35deg);
                }
            }
        }
    
        &:active {
            & > div {
                &:nth-child(1){
                    transform: rotate(0);
                }
                &:nth-child(3){
                    transform: rotate(0);
                }
            }
        }
    }
}

.dark-mode {
    & .header-bkg {
        background-color: rgba($neutral-900, .8);
    }

    & .navitems-container {
        @media (nmcdbp(medium, down)) {
            background-color: rgba($neutral-900, .85);
        }
    }
    & .menu-button {
        color: $neutral-100;

        & > div {
            background-color: $neutral-100;
        }
    
        &:active {
            & > div {
                background-color: $neutral-100;
                background: $neutral-100;
            }
        }
    }
}