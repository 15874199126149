@import './Util/allUtils';

.snackbarbase{
    position: fixed;
    bottom: spacing(3);
    left: spacing(5);
    max-width: calc(100vw - spacing(10));
    border-width: 1px;
    border-style: solid;
    border-radius: 24px;
    padding: spacing(1) spacing(1) spacing(1) spacing(3);
    display: flex;
    align-items: center;
    overflow: hidden;
    transform: translateY(0) scale(1);
    opacity: 1;
    transition: transform 400ms ease-out, opacity 200ms ease-out;
    z-index: 5000;

    @media (nmcdbp(medium, only)) {
        left: spacing(3);
        max-width: calc(100vw - spacing(6));
    }
    @media (nmcdbp(small, only)) {
        left: 0;
        bottom: 0;
        width: calc(100vw - 48px);
        max-width: unset;
        border-radius: 24px 24px 0 0;
    }

    & > i{
        font-size: fontsizing(18);
        line-height: fontsizing(24);
        margin-right: spacing(1);
    }

    & > button {
        margin-left: spacing(2);
        @media (nmcdbp(small, only)) {
            margin-left: auto;
        }
    }

    & > .snackbar-countdown{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 24px;
        z-index: -1;
        transition: width 500ms linear;

        @media (nmcdbp(small, only)) {
            border-radius: 24px 24px 0 0;
        }
    }

    &.exit-screen{
        transform: translateY(200px) scale(.8);
        opacity: 0;
    }
}

.snackbar{
    &-success{
        @extend .snackbarbase;
        color: $green-800;
        background-color: $neutral-100;
        border-color: $green-300;

        & > i{
            color: $green-800;
        }

        & > .snackbar-contents{
            color: $green-800;
        }

        & > .snackbar-countdown{
            background-color: $green-100;
        }
    }
    &-info{
        @extend .snackbarbase;
        color: $blue-800;
        background-color: $neutral-100;
        border-color: $blue-300;

        & > i{
            color: $blue-800;
        }

        & > .snackbar-contents{
            color: $blue-800;
        }

        & > .snackbar-countdown{
            background-color: $blue-100;
        }
    }
    &-error{
        @extend .snackbarbase;
        color: $red-800;
        background-color: $neutral-100;
        border-color: $red-300;

        & > i{
            color: $red-800;
        }

        & > .snackbar-contents{
            color: $red-800;
        }

        & > .snackbar-countdown{
            background-color: $red-100;
        }
    }
    &-warning{
        @extend .snackbarbase;
        color: $yellow-800;
        background-color: $neutral-100;
        border-color: $yellow-300;

        & > i{
            color: $yellow-800;
        }

        & > .snackbar-contents{
            color: $yellow-800;
        }

        & > .snackbar-countdown{
            background-color: $yellow-100;
        }
    }
}

.dark-mode{
    &.snackbar{
        &-success{
            @extend .snackbarbase;
            color: $green-100;
            background-color: $neutral-900;
            border-color: $green-600;
    
            & > i{
                color: $green-100;
            }
    
            & > .snackbar-contents{
                color: $green-100;
            }
    
            & > .snackbar-countdown{
                background-color: $green-800;
            }
        }
        &-info{
            @extend .snackbarbase;
            color: $blue-100;
            background-color: $neutral-900;
            border-color: $blue-600;
    
            & > i{
                color: $blue-100;
            }
    
            & > .snackbar-contents{
                color: $blue-100;
            }
    
            & > .snackbar-countdown{
                background-color: $blue-800;
            }
        }
        &-error{
            @extend .snackbarbase;
            color: $red-100;
            background-color: $neutral-900;
            border-color: $red-600;
    
            & > i{
                color: $red-100;
            }
    
            & > .snackbar-contents{
                color: $red-100;
            }
    
            & > .snackbar-countdown{
                background-color: $red-800;
            }
        }
        &-warning{
            @extend .snackbarbase;
            color: $yellow-100;
            background-color: $neutral-900;
            border-color: $yellow-600;
    
            & > i{
                color: $yellow-100;
            }
    
            & > .snackbar-contents{
                color: $yellow-100;
            }
    
            & > .snackbar-countdown{
                background-color: $yellow-800;
            }
        }
    }
}