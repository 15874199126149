@import 'Util/allUtils';
@import './Button.scss';

a {
    img {
        border: 0;
    }
}

.linkBase{
    font-family: $body-font-family;
    font-size: fontsizing(18);
    font-weight: 400;
    line-height: fontsizing(24);
    transition: $standard-transition;
    cursor: pointer;
    display: inline-flex;
    flex-flow: row nowrap;
    min-height: 24px;

    &:hover,
    &:focus {
        & > i:last-child {
            transform: translateX(spacing(.5));
        }
    }

    & > i:last-child {
        padding-left: spacing(1);
        font-size: fontsizing(16);
        transition: $standard-transition;
    }
}

.link{
    &-default{
        @extend .linkBase;
        color: $interactiveColor-700;
        text-decoration: $interactiveColor-700;
    
        &:hover,
        &:focus {
            color: $interactiveColor-800;
            text-decoration: $interactiveColor-800;
        }
    }
}

.link{
    &-locked{
        @extend .linkBase;
        color: $interactiveColor-700;
        text-decoration: $interactiveColor-700;
        border: none;
        background-color: transparent;
    
        &:hover,
        &:focus {
            color: $interactiveColor-800;
            text-decoration: $interactiveColor-800;
        }

        & > i:first-child {
            padding-right: spacing(1);
            font-size: fontsizing(16);
        }
    }
}

.link{
    &-external{
        @extend .linkBase;
        color: $interactiveColor-700;
        text-decoration: $interactiveColor-700;
    
        &:hover,
        &:focus {
            color: $interactiveColor-800;
            text-decoration: $interactiveColor-800;
        }
    }
}

.link{
    &-button-filled{
        @extend .button-filled;
        min-height: auto;
        text-decoration: none;
    }
    &-button-outline{
        @extend .button-outline;
        min-height: auto;
        text-decoration: none;
    }
    &-linkedin{
        @extend .button-hollow;
        min-height: auto;
        text-decoration: none;
    }
}

.link {
    &-coming-soon {
        font-family: $body-font-family;
        font-size: fontsizing(18);
        font-weight: 400;
        line-height: fontsizing(24);
        color: $neutral-700;
        cursor: not-allowed;
        display: inline-flex;
        flex-flow: row nowrap;
        min-height: 24px;

        & i {
            padding-right: spacing(1);
            font-size: fontsizing(16);
        }
    }
}

.dark-mode .link{
    &-default{
        color: $interactiveColor-400;
        text-decoration: $interactiveColor-400;
    
        &:hover,
        &:focus {
            color: $interactiveColor-300;
            text-decoration: $interactiveColor-300;
        }
    }
    &-locked{
        color: $interactiveColor-400;
        text-decoration: $interactiveColor-400;
    
        &:hover,
        &:focus {
            color: $interactiveColor-300;
            text-decoration: $interactiveColor-300;
        }
    }
    &-external{
        color: $interactiveColor-400;
        text-decoration: $interactiveColor-400;
    
        &:hover,
        &:focus {
            color: $interactiveColor-300;
            text-decoration: $interactiveColor-300;
        }
    }
    &-coming-soon {
        color: $neutral-100;
    }
}
