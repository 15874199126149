@import './Util/allUtils';

.work-hero-block{
    padding-top: spacing(25);
    padding-bottom: spacing(15);

    @media (nmcdbp(medium, only)) {
        padding-top: spacing(20);
    }
    @media (nmcdbp(small, only)) {
        padding-top: spacing(15);
    }

    & h1 {
        margin-bottom: spacing(3);

        @media (nmcdbp(medium, only)) {
            margin-bottom: spacing(2);
        }
        @media (nmcdbp(small, only)) {
            margin-bottom: spacing(1.5);
        }
    }

    & p {
        margin-bottom: spacing(8);

        @media (nmcdbp(medium, only)) {
            margin-bottom: spacing(6);
        }
        @media (nmcdbp(small, only)) {
            margin-bottom: spacing(5);
        }
    }

    & img {
        width: 100%;
    }
}

.work-together-block{
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: spacing(-5);
    margin-right: spacing(-5);
    width: 100%;
    padding-left: spacing(5);
    padding-right: spacing(5);
    padding-top: spacing(10);
    padding-bottom: spacing(10);
    @include frosted-1;
    @media (nmcdbp(medium, down)) {
        margin-left: spacing(-3);
        margin-right: spacing(-3);
        padding-left: spacing(3);
        padding-right: spacing(3);
    }
}

.dark-mode .work-together-block{
    @include frosted-1-dark
}