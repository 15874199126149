@import './Util/allUtils';

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    justify-content: center;
    align-items: center;

    &.grid-x-has-gutters{
        left: spacing(1.5);

        @media (nmcdbp(small, only)) {
            left: spacing(1);
        }
    }
}

.modal-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($neutral-300, .16);
    backdrop-filter: blur(40px);
}

.modal-block{
    position: relative;
    z-index: 950;
    padding: spacing(4);
    background-color: $neutral-100;
    border-radius: 32px; 
    width: 668px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - spacing(8));

    @media (nmcdbp(medium, only)) {
        width: 600px;
    }
    @media (nmcdbp(small, only)) {
        width: calc(100vw - spacing(8));
        height: calc(100vh - spacing(8));
        border-radius: 0; 
        
    }

    & .modal-close{
        position: absolute;
        top: spacing(2);
        right: spacing(2);
    }



    &-full{
        width: calc(100% - spacing(10));
        height: calc(100% - spacing(10));
        border-radius: 0;
        background-color: $neutral-100;
        padding: spacing(5);


        & .modal-close{
            position: absolute;
            top: spacing(1);
            right: spacing(1);
        }
    }
}

.dark-mode{
    & .modal-overlay{
        background-color: rgba($neutral-700, .16);
    }
    & .modal-block{
        background-color: $neutral-900;
        &-full{
            background-color: $neutral-900;
        }
    }
}