@import './Util/allUtils';

.input-text-container, .input-select-container {
    display: flex;
    flex-flow: column nowrap;
}

.input-text-label {
    font-family: $body-font-family;
    font-size: fontsizing(18);
    line-height: fontsizing(24);
    margin-bottom: spacing(1);
    color: $neutral-900;

    & > span {
        font-size: fontsizing(14);
        line-height: fontsizing(16);
        color: $neutral-700;
        padding-left: spacing(1);
    }

    &.error-state {
        color: $red-600;
    }
}

.dark-mode .input-text-label{
    color: $neutral-100;

    & > span {
        color: $neutral-300;
    }

    &.error-state {
        color: $red-200;
    }
}

.input-text-errormessage{
    font-size: fontsizing(16);
    line-height: fontsizing(20);
    margin-top: spacing(1);
    color: $red-600;
    transition: $standard-transition;
    display: none;

    &.error-state{
        display: block;
    }
}

.dark-mode .input-text-errormessage {
    color: $red-200;
}

input[type=text], textarea{
    font-family: $body-font-family;
    font-size: fontsizing(18);
    line-height: fontsizing(24);
    border: 1px $neutral-600 solid;
    border-radius: 16px;
    padding: spacing(1.5) spacing(2);
    outline: 0;
    color: $neutral-900;
    background-color: transparent;
    transition: $standard-transition;

    &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: $neutral-700;
        opacity: 1; 
    }

    //this is the filled state
    &:not(:placeholder-shown) {
        background-color: $neutral-100;
        border-color: $neutral-900;
    } 
    
    &:active, &:focus {
        background-color: $neutral-100 !important;
        border-color: $neutral-900 !important;
        
        &.error-state{
            border-color: $red-700 !important;
            outline-color: $red-700 !important;
        }
    }

    &:hover{
        background-color: $interactiveColor-100;
        border-color: $interactiveColor-800;

        &.error-state{
            background-color: $red-100;
        }
    }

    &.error-state {
        outline: 1px $red-600 solid;
        border-color: $red-600;
    }
}

.dark-mode input[type=text], .dark-mode textarea{
    border-color: $neutral-200;
    color: $neutral-100;

    &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: $neutral-300;
    }

    //this is the filled state
    &:not(:placeholder-shown) {
        background-color: $neutral-900;
        border-color: $neutral-100;
    } 
    
    &:active, &:focus {
        background-color: $neutral-900 !important;
        border-color: $interactiveColor-200 !important;
        
        &.error-state{
            border-color: $red-300 !important;
            outline-color: $red-300 !important;
        }
    }

    &:hover{
        background-color: $interactiveColor-900;
        border-color: $interactiveColor-200;

        &.error-state{
            background-color: $red-900;
        }
    }

    &.error-state {
        outline-color: $red-300;
        border-color: $red-300;
    }
}

textarea {
    min-height: 160px;
}

.input-select-container{
    input {
        display: none;
    }

    & .select-container{
        position: relative;
    }
}

.select-field-element {
    position: relative;
    font-family: $body-font-family;
    font-size: fontsizing(18);
    line-height: fontsizing(24);
    border: 1px $neutral-600 solid;
    border-radius: 16px;
    padding: spacing(1.5) spacing(2);
    outline: 0;
    color: $neutral-900;
    background-color: transparent;
    transition: $standard-transition;
    cursor: pointer;

    &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: $neutral-700;
        opacity: 1; 
    }

    &::after{
        content: '\F282';
        font-size: fontsizing(24);
        line-height: fontsizing(24);
        font-family: 'bootstrap-icons' !important;
        position: absolute;
        right: 16px;
        top: 12px;
        color: $neutral-700;
        transition: $standard-transition;
    }

    &.select-filled, &.is-open{
        background-color: $neutral-100;
        border-color: $neutral-900;

        &::after{
            color: $neutral-900;
            transform: rotate(180deg);
        }
    }
    
    &:active, &:focus {
        background-color: $neutral-100 !important;
        border-color: $neutral-900 !important;
        
        &::after{
            color: $neutral-900;
        }
        
        &.error-state{
            border-color: $red-700 !important;
            outline-color: $red-700 !important;

            &::after{
                color: $red-700;
            }
        }
    }

    &:hover{
        background-color: $interactiveColor-100;
        border-color: $interactiveColor-800;
        &::after{
            color: $interactiveColor-800;
        }

        &.error-state{
            background-color: $red-100;
        }
    }

    &.error-state {
        outline: 1px $red-600 solid;
        border-color: $red-600;
        &::after{
            color: $red-600;
        }
    }   
}

.dark-mode .select-field-element {
    border-color: $neutral-200;
    color: $neutral-100;

    &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: $neutral-300;
    }

    &::after{
        color: $neutral-300;
    }

    &.select-filled, &.is-open{
        background-color: $neutral-900;
        border-color: $neutral-100;

        &::after{
            color: $neutral-100;
        }
    }
    
    &:active, &:focus {
        background-color: $neutral-900 !important;
        border-color: $interactiveColor-200 !important;
        
        &::after{
            color: $neutral-200;
        }
        
        &.error-state{
            border-color: $red-300 !important;
            outline-color: $red-300 !important;

            &::after{
                color: $red-300;
            }
        }
    }

    &:hover{
        background-color: $interactiveColor-900;
        border-color: $interactiveColor-200;
        &::after{
            color: $interactiveColor-200;
        }

        &.error-state{
            background-color: $red-900;
        }
    }

    &.error-state {
        outline-color: $red-300;
        border-color: $red-300;
        &::after{
            color: $red-300;
        }
    }   
}

.select-option-container{
    position: absolute;
    top: calc(100% + 8px);
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    background-color: $neutral-100;
    border: 1px $neutral-300 solid;
    border-radius: 16px;
    // z-index: -5;
    transform: translateY(-32px);
    opacity: 0;
    //height: 0;
    transition: opacity 200ms ease-out, transform 200ms ease-out;
    overflow: hidden;

    & .select-option{
        font-family: $body-font-family;
        font-size: fontsizing(18);
        line-height: fontsizing(24);
        padding: 12px 16px;
        cursor: pointer;
        color: $neutral-900;

        &:hover, &:active, &:focus{
            background-color: $interactiveColor-100;
            outline: none;
        }

        &.is-selected {
            background-color: $neutral-200;
        }
    }

    & .select-option:not(:last-child){
        margin-bottom: 4px;
    }

    &.is-open{
        height: auto;
        opacity: 1;
        transform: translateY(0);
        z-index: 10;
    }
}

.dark-mode .select-option-container{
    background-color: $neutral-900;
    border-color: $neutral-700;

    & .select-option{
        color: $neutral-100;
        &:hover, &:active, &:focus{
            background-color: $interactiveColor-900;
        }

        &.is-selected {
            background-color: $neutral-800;
        }
    }
}

.input-radio{
    &-group-options-container{
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        margin-left: spacing(1);

        & .input-radio-container{
            margin-top: spacing(.5);
        }
    }
    &-group-label{
        & > span {
            padding-left: spacing(1);
        }
    }
    &-group-explanation{
        margin-top: spacing(.5);
    }
    &-container{
        display: inline-flex;
        flex-flow: row nowrap;
        min-height: 24px;
        min-width: 48px;
        padding-top: spacing(1.5);
        padding-bottom: spacing(1.5);
        cursor: pointer;
    }
    &-label-explanation-container{
        margin-left: spacing(1);
    }
    &-label{
        @extend .input-text-label;
        // display: inline-block;
        margin-bottom: 0;
    }
    &-explanation{
        font-size: fontsizing(16);
        line-height: fontsizing(20);
        margin-top: spacing(1);
        color: $neutral-700;
    }
    &-control{
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: spacing(.5) 0 0;
        width: 16px;
        height: 16px;
        min-width: 16px;
        position: relative;
        outline: none;
        cursor: pointer;
        
        &::before{
            content: '';
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border-radius: 8px;
            border: 1px $interactiveColor-700 solid;
            outline: 0 $interactiveColor-200 solid;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: $standard-transition;
        }

        &::after{
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: $standard-transition;
            transform: scale(0);
        }

        &:hover{
            &::before{
                border-color: $interactiveColor-800;
                outline-width: 2px;
            }
            &::after{
                opacity: 1;
                background-color: $interactiveColor-100;
                transform: scale(1);
            }
        }

        &:focus-visible{
            &::before{
                border-color: $interactiveColor-800;
            }
            &::after{
                opacity: 1;
                background-color: $interactiveColor-100;
                transform: scale(.625);
            }
        }

        &:checked{
            &::after{
                opacity: 1;
                transform: scale(1);
                background-color: $interactiveColor-700;
            }
            &:hover::after{
                background-color: $interactiveColor-800;
            }
            &:focus-visible{
                &::after{
                    opacity: 1;
                    background-color: $interactiveColor-800;
                    transform: scale(.625);
                }
            }
        }
    }
}

.dark-mode .input-radio{
    &-explanation{
        color: $neutral-300;
    }
    &-control{       
        &::before{
            border-color: $interactiveColor-400;
            outline-color: $interactiveColor-800;
        }

        &:hover{
            &::before{
                border-color: $interactiveColor-300;
            }
            &::after{
                background-color: $interactiveColor-800;
            }
        }

        &:focus-visible{
            &::before{
                border-color: $interactiveColor-300;
            }
            &::after{
                background-color: $interactiveColor-800;
            }
        }

        &:checked{
            &::after{
                background-color: $interactiveColor-400;
            }
            &:hover::after{
                background-color: $interactiveColor-300;
            }
            &:focus-visible{
                &::after{
                    background-color: $interactiveColor-300;
                }
            }
        }
    }
}