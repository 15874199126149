@import './Util/allUtils';

.featured-section {
    &-container{
        margin-bottom: spacing(7);
    }

    &-header{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: spacing(2);
    }

    // &-card-group{

    // }
}

.featured-card-container{
    display: flex;
    text-decoration: none;
    margin-bottom: spacing(3);
}