@import './Util/allUtils';

.scrolling-background{
    &-container{
        width: 100vw;
        height: 100vh;
        background-color: $neutral-100;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -500;
    }
    &-img{
        width: 100vw;
        height: 100%;
        opacity: .9;
        background-image: url('./Util/Imgs/PaperBackground@50.webp');
        background-repeat: no-repeat;
        background-position: center 0px;
        transition: top 500ms ease-out;

        @media (nmcdbp(xlarge, up)) {
            background-image: url('./Util/Imgs/PaperBackground.webp');
        }
    }
}

.dark-mode {
    &.scrolling-background-container{
        background-color: $neutral-900;
    }
    & .scrolling-background-img{
        background-image: url('./Util/Imgs/PaperBackgroundDark@50.webp');
        @media (nmcdbp(xlarge, up)) {
            background-image: url('./Util/Imgs/PaperBackgroundDark.webp');
        }
    }
}

// .scrolling-image-background{
//     width: 100vw;
//     height: 100vh;
//     opacity: .9;
//     background-color: $neutral-100;
//     background-image: url('./Util/Imgs/PaperBackground@50.webp');
//     background-repeat: no-repeat;
//     background-position: center 0px;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: -500;
//     transition: top 200ms ease-in-out;
    
//     @media (nmcdbp(xlarge, up)) {
//         background-image: url('./Util/Imgs/PaperBackground.webp');
//     }
// }