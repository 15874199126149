@import './Util/allUtils';

.scroll-on-container {
    transition: $long-transition;
    transform: scale(.8) translateY(80px);
    opacity: 0;

    &.has-scrolled-on {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}