@import './Util/allUtils';

.cardbase {
    padding: spacing(3);
    border-radius: spacing(3);
    border: 1px $neutral-300 solid;
    background-color: $neutral-100;
    transition: $standard-transition;
    outline: 0 $neutral-200 solid;

    &.is-clickable{
        text-decoration: none;
        cursor: pointer;
        
        &:hover, &:focus, &:focus-visible {
            outline-width: 2px;
            border-color: $neutral-400;
        }
        &:active {
            outline: 0;
        }
    }

    &.project-highlight-card{
        padding-top: spacing(6);
        margin-top: spacing(-6);
        flex-grow: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
}

.dark-mode .cardbase{
    border-color: $neutral-700;
    background-color: $neutral-900;
    outline-color: $neutral-800;
    
    &.is-clickable{
        &:hover, &:focus, &:focus-visible {
            border-color: $neutral-600;
        }
    }
}

.red-card{
    border-color: $red-300;
    outline-color: $red-200;
    
    &.is-clickable{
        &:hover, &:focus, &:focus-visible {
            border-color: $red-400;
        }
    }
}

.orange-card{
    border-color: $orange-300;
    outline-color: $orange-200;

    &.is-clickable{
        &:hover, &:focus, &:focus-visible {
            border-color: $orange-400;
        }
    }
}

.yellow-card{
    border-color: $yellow-300;
    outline-color: $yellow-200;

    &.is-clickable{
        &:hover, &:focus, &:focus-visible {
            border-color: $yellow-400;
        }
    }
}

.green-card{
    border-color: $green-300;
    outline-color: $green-200;

    &.is-clickable{
        &:hover, &:focus, &:focus-visible {
            border-color: $green-400;
        }
    }
}

.blue-card{
    border-color: $blue-300;
    outline-color: $blue-200;

    &.is-clickable{
        &:hover, &:focus, &:focus-visible {
            border-color: $blue-400;
        }
    }
}

.purple-card{
    border-color: $purple-300;
    outline-color: $purple-200;

    &.is-clickable{
        &:hover, &:focus, &:focus-visible {
            border-color: $purple-400;
        }
    }
}

.dark-mode {
    & .red-card{
        border-color: $red-700;
        outline-color: $red-800;
        
        &.is-clickable{
            &:hover, &:focus, &:focus-visible {
                border-color: $red-600;
            }
        }
    }
    
    & .orange-card{
        border-color: $orange-700;
        outline-color: $orange-800;
    
        &.is-clickable{
            &:hover, &:focus, &:focus-visible {
                border-color: $orange-600;
            }
        }
    }
    
    & .yellow-card{
        border-color: $yellow-700;
        outline-color: $yellow-800;
    
        &.is-clickable{
            &:hover, &:focus, &:focus-visible {
                border-color: $yellow-600;
            }
        }
    }
    
    & .green-card{
        border-color: $green-700;
        outline-color: $green-800;
    
        &.is-clickable{
            &:hover, &:focus, &:focus-visible {
                border-color: $green-600;
            }
        }
    }
    
    & .blue-card{
        border-color: $blue-700;
        outline-color: $blue-800;
    
        &.is-clickable{
            &:hover, &:focus, &:focus-visible {
                border-color: $blue-600;
            }
        }
    }
    
    & .purple-card{
        border-color: $purple-700;
        outline-color: $purple-800;
    
        &.is-clickable{
            &:hover, &:focus, &:focus-visible {
                border-color: $purple-600;
            }
        }
    }
}