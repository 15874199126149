@import './Util/allUtils';

.project-scroll-bar-sticky-container{
    margin-top: spacing(20);
}
.project-scroll-bar-container{
    position: sticky;
    top: 15vh;
    height: 70vh;
    width: 24px;
    z-index: 500;
    margin: auto;
}

.project-scroll-bar-home{
    font-size: fontsizing(24);
    line-height: fontsizing(24);
    margin: 0 0 4px;
    width: 24px;
    transition: $standard-transition;
    cursor: pointer;

    &-label{
        display: none;
    }

    &.project-{
        &red{
            color: $red-500;
            &:hover{
                color: $red-700;
                background-color: $red-100;
            }
        }
        &orange{
            color: $orange-500;
            &:hover{
                color: $orange-700;
                background-color: $orange-100;
            }
        }
        &yellow{
            color: $yellow-500;
            &:hover{
                color: $yellow-700;
                background-color: $yellow-100;
            }
        }
        &green{
            color: $green-500;
            &:hover{
                color: $green-700;
                background-color: $green-100;
            }
        }
        &blue{
            color: $blue-500;
            &:hover{
                color: $blue-700;
                background-color: $blue-100;
            }
        }
        &purple{
            color: $purple-500;
            &:hover{
                color: $purple-700;
                background-color: $purple-100;
            }
        }
    }
}

.project-scroll-bar-segment {
    width: 24px;

    &-separator{
        width: 24px;
        height: 4px;
        //background-color: $neutral-100;
    }

    &s-container {
        position: relative;
        height: calc(100% - 40px);
        width: 24px;
        overflow: hidden;
        border-radius: 4px;
    }
}

.project-scroll-bar-scroll-indicator{
    position: absolute;
    transition: $standard-transition;
    top: 0;
    left: 0;
    width: 24px;
    min-height: 8px !important;
    background-color: $gradient-500;
    border-radius: 0 0 4px 4px;
    z-index: -1;

    &.project-{
        &red{
            background-color: $red-500;
        }
        &orange{
            background-color: $orange-500;
        }
        &yellow{
            background-color: $yellow-500;
        }
        &green{
            background-color: $green-500;
        }
        &blue{
            background-color: $blue-500;
        }
        &purple{
            background-color: $purple-500;
        }
    }
}
.project-scroll-bar-scroll-indicator-bkg{
    position: absolute;
    transition: $standard-transition;
    top: 0;
    left: 0;
    width: 24px;
    min-height: 100% !important;
    background-color: $neutral-200;
    border-radius: 4px;
    z-index: -5;
}

.dark-mode {
    & .project-scroll-bar-home{
        &.project-{
            &red{
                color: $red-500;
                &:hover{
                    color: $red-300;
                    background-color: $red-900;
                }
            }
            &orange{
                color: $orange-500;
                &:hover{
                    color: $orange-300;
                    background-color: $orange-900;
                }
            }
            &yellow{
                color: $yellow-500;
                &:hover{
                    color: $yellow-300;
                    background-color: $yellow-900;
                }
            }
            &green{
                color: $green-500;
                &:hover{
                    color: $green-300;
                    background-color: $green-900;
                }
            }
            &blue{
                color: $blue-500;
                &:hover{
                    color: $blue-300;
                    background-color: $blue-900;
                }
            }
            &purple{
                color: $purple-500;
                &:hover{
                    color: $purple-300;
                    background-color: $purple-900;
                }
            }
        }
    }
    & .project-scroll-bar-scroll-indicator-bkg{
        background-color: $neutral-700;
    }
}