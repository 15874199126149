@import 'Util/allUtils';

body{
  display: block;
  margin: unset;
  min-height: 100vh;
  //overflow-x: hidden;

  &.stop-scroll {
    overflow-y: hidden;
  }
}

html.modal-stop-scroll{
  overflow: hidden;
}

#root{
  min-height: 100vh;
}

// Typography resets
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
    font-family: $body-font-family;
    margin: 0;
    padding: 0;
}

/*#region GRID*/
.nmcd-page-container {
  width: calc(100% - spacing(10));
  padding-left: spacing(5);
  padding-right: spacing(5);
  @media (nmcdbp(medium, down)) {
    width: calc(100% - spacing(6));
    padding-left: spacing(3);
    padding-right: spacing(3);
  }
}

.major-content-section{
  padding-top: spacing(10);
}

.minor-content-section {
  padding-top: spacing(6);
}

.grid-x{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;

  &-has-gutters{
    @extend .grid-x;
    margin-left: spacing(-1.5);
    margin-right: spacing(-1.5);
    
    @media (nmcdbp(small, only)) {
      margin-left: spacing(-1);
      margin-right: spacing(-1);
    }
  }
}

.cell {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}

.align{
  &-middle{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@for $i from 1 through 12 {
  .grid-x > .small-#{$i} {
    @media (nmcdbp(small, up)) {
      width: calc(($i / 12) * 100%);
    }
  }
  .grid-x-has-gutters > .small-#{$i} {
    @media (nmcdbp(medium, up)) {
      width: calc((($i / 12) * 100%) - spacing(3));
      margin-left: spacing(1.5);
      margin-right: spacing(1.5);
    }
    @media (nmcdbp(small, only)) {
      width: calc((($i / 12) * 100%) - spacing(2));
      margin-left: spacing(1);
      margin-right: spacing(1);
    }
  }
  .grid-x-has-gutters {
    & > .small-#{$i} {
      @media (nmcdbp(small, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
      @media (nmcdbp(small, only)) {
        width: calc((($i / 12) * 100%) - spacing(2));
        margin-left: spacing(1);
        margin-right: spacing(1);
      }
    }
    & > .small-offset {
      &-left-#{$i}{
        @media (nmcdbp(small, only)) {
          margin-left: calc((($i / 12) * 100%) + spacing(1)) !important;
        }
        @media (nmcdbp(small, up)) {
          margin-left: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
      &-right-#{$i}{
        @media (nmcdbp(small, only)) {
          margin-right: calc((($i / 12) * 100%) + spacing(1)) !important;
        }
        @media (nmcdbp(small, up)) {
          margin-right: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
    }
  }
}
@for $i from 1 through 12 {
  .grid-x > .medium-#{$i} {
    @media (nmcdbp(medium, up)) {
      width: calc(($i / 12) * 100%);
    }
  }
  .grid-x-has-gutters {
    & > .medium-#{$i} {
      @media (nmcdbp(medium, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
    & > .medium-offset {
      &-left-#{$i}{
        @media (nmcdbp(medium, up)) {
          margin-left: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
      &-right-#{$i}{
        @media (nmcdbp(medium, up)) {
          margin-right: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
    }
  }
}
@for $i from 1 through 12 {
  .grid-x > .large-#{$i} {
    @media (nmcdbp(large, up)) {
      width: calc(($i / 12) * 100%);
    }
  }
  .grid-x-has-gutters {
    & > .large-#{$i} {
      @media (nmcdbp(large, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
    & > .large-offset {
      &-left-#{$i}{
        @media (nmcdbp(large, up)) {
          margin-left: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
      &-right-#{$i}{
        @media (nmcdbp(large, up)) {
          margin-right: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
    }
  }
}
@for $i from 1 through 12 {
  .grid-x > .xlarge-#{$i} {
    @media (nmcdbp(xlarge, up)) {
      width: calc(($i / 12) * 100%);
    }
  }
  .grid-x-has-gutters {
    & > .xlarge-#{$i} {
      @media (nmcdbp(xlarge, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
    & > .xlarge-offset {
      &-left-#{$i}{
        @media (nmcdbp(xlarge, up)) {
          margin-left: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
      &-right-#{$i}{
        @media (nmcdbp(xlarge, up)) {
          margin-right: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
    }
  }
}
@for $i from 1 through 12 {
  .grid-x > .xxlarge-#{$i} {
    @media (nmcdbp(xxlarge, up)) {
      width: calc(($i / 12) * 100%);
    }
  }
  .grid-x-has-gutters {
    & > .xxlarge-#{$i} {
      @media (nmcdbp(xxlarge, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
    & > .xxlarge-offset {
      &-left-#{$i}{
        @media (nmcdbp(xxlarge, up)) {
          margin-left: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
      &-right-#{$i}{
        @media (nmcdbp(xxlarge, up)) {
          margin-right: calc((($i / 12) * 100%) + spacing(1.5)) !important;
        }
      }
    }
  }
}

.grid-x-has-gutters > .large-offset-left-0{
  @media (nmcdbp(large, up)) {
    margin-left: spacing(1.5) !important;
  }
}
.hide-on-small{
  @media (nmcdbp(small, only)) {
    display: none !important;
  }
}
.hide-on-medium{
  @media (nmcdbp(medium, only)) {
    display: none !important;
  }
}
.hide-on-medium-down{
  @media (nmcdbp(medium, down)) {
    display: none !important;
  }
}
.hide-on-large-up{
  @media (nmcdbp(large, up)) {
    display: none !important;
  }
}
/*#endregion*/

/*#region spacing*/

@for $i from 1 through 15 {
  .margin-b-#{$i} {
    margin-bottom: spacing($i);
  }
  .margin-t-#{$i} {
    margin-top: spacing($i);
  }

  $half: $i - .5;

  .margin-b-#{$i}-5 {
    margin-bottom: spacing($half);
  }
  .margin-t-#{$i}-5 {
    margin-top: spacing($half);
  }
}

/*#endregion*/

/*#region Typography*/

.type-heading-1 {
  font-family: $heading-font-family;
  font-size: fontsizing(56);
  font-weight: 500;
  line-height: fontsizing(68);
  color: $neutral-900;

  @media (nmcdbp(small, only)) {
    font-size: fontsizing(36);
    line-height: fontsizing(44);
  }
}
.type-heading-2 {
  font-family: $heading-font-family;
  font-size: fontsizing(48);
  font-weight: 400;
  line-height: fontsizing(58);
  color: $neutral-900;

  @media (nmcdbp(small, only)) {
    font-size: fontsizing(32);
    line-height: fontsizing(38);
  }
}
.type-heading-3 {
  font-family: $heading-font-family;
  font-size: fontsizing(36);
  font-weight: 400;
  line-height: fontsizing(44);
  color: $neutral-900;

  @media (nmcdbp(small, only)) {
    font-size: fontsizing(28);
    line-height: fontsizing(34);
  }
}

.type-subhead-1 {
  font-family: $heading-font-family;
  font-size: fontsizing(28);
  font-weight: 500;
  line-height: fontsizing(34);
  color: $neutral-900;

  @media (nmcdbp(small, only)) {
    font-size: fontsizing(24);
    line-height: fontsizing(30);
  }
}
.type-subhead-2 {
  font-family: $heading-font-family;
  font-size: fontsizing(24);
  font-weight: 400;
  line-height: fontsizing(30);
  color: $neutral-900;

  @media (nmcdbp(small, only)) {
    font-size: fontsizing(20);
    line-height: fontsizing(24);
  }
}

.type-body {
  font-family: $body-font-family;
  font-size: fontsizing(18);
  font-weight: 400;
  line-height: fontsizing(24);
  color: $neutral-900;

  @media (nmcdbp(small, only)) {
    font-size: fontsizing(16);
    line-height: fontsizing(20);
  }
}

.type-caption {
  font-family: $body-font-family;
  font-size: fontsizing(16);
  font-weight: 400;
  line-height: fontsizing(20);
  color: $neutral-700 ;

  @media (nmcdbp(small, only)) {
    font-size: fontsizing(14);
    line-height: fontsizing(16);
  }
}

.type-ul{
  list-style-type: none;

  & li{
    display: flex;
    align-items: center;
    //margin-left: spacing(4);

    &::marker {
      content: '';
    }

    &::before {
      content: '\F309';
      color: $neutral-900;
      font-family: "bootstrap-icons" !important;
      line-height: fontsizing(18);
      padding-top: spacing(.5);
    }
  }
}

.dark-mode{
  & .type-heading-1, & .type-heading-2, & .type-heading-3,
  & .type-subhead-1, & .type-subhead-2, & .type-body, & .type-ul li::before {
    color: $neutral-100;
  }
  & .type-caption{
    color: $neutral-300;
  }
}

/*#endregion*/

.page-container-nmcd {
  width: 100%;
  display: flex;
  -ms-flex-flow: row nowrap;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
