@import './Util/allUtils';

.skill-card-list{
    margin-bottom: spacing(-3);
}

.skill-card-cell{
    display: flex;
    margin-bottom: spacing(3);
}

.skill-name-container{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: spacing(2);

    & img{
        height: 32px;
        padding-right: spacing(2);
    }
}