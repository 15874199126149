@import './Util/allUtils';

.about-me-hero-block {
    padding-top: spacing(25);
    padding-bottom: spacing(15);
    flex-flow: row-reverse;

    @media (nmcdbp(medium, only)) {
        padding-top: spacing(20);
        flex-flow: row wrap;
    }
    @media (nmcdbp(small, only)) {
        padding-top: spacing(15);
        flex-flow: row wrap;
    }

    & img {
        width: 100%;
        border-radius: 32px;

        @media (nmcdbp(medium, only)) {
            margin-top: spacing(8);
        }
        @media (nmcdbp(small, only)) {
            margin-top: spacing(6);
        }
    }

    & h1 {
        margin-bottom: spacing(3);

        @media (nmcdbp(medium, only)) {
            margin-bottom: spacing(2);
        }
        @media (nmcdbp(small, only)) {
            margin-bottom: spacing(1.5);
        }
    }

    & p {
        margin-bottom: spacing(3);
    }

    .about-me-hero-content {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    }
}

.education-section {
    display: grid;
    align-content: flex-start;
    margin-bottom: spacing(-3);

    & .education-card{
        margin-bottom: spacing(3);
    }
}

.recs-group-container{
    margin-bottom: spacing(-3);
}
.recs-container{
    display: flex;
    margin-bottom: spacing(3);
}

.test-grid-underlay{
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 80px);
    height: 100vh;
    z-index: -50;
    padding: 0 40px;

    @media (nmcdbp(medium, down)) {
        width: calc(100vw - 40px);
        padding: 0 24px;
    }

    & > div {
        background-color: rgba($red-100, 1);
    }
}