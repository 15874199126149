@import './Util/allUtils';

.project-highlight {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    &-img{
        max-width: 100%;
        max-height: 344px;
        transition: $standard-transition;

        @media (nmcdbp(medium, down)) {
            max-height: 200px;
        }

        &-container{
            display: flex;
            padding-left: spacing(4.5);
            padding-right: spacing(4.5);
            width: calc(100% - spacing(9));
            justify-content: center;
            align-items: center;
            z-index: 1;

            cursor: zoom-in;
            &:hover img{
                transform: scale(1.1);
            }
        }
    }

    &.cardbase {
        z-index: 0;
    }
}