@import './Util/allUtils';

.footer-nmcd {
    background-color: $neutral-200;
    color: $neutral-900;
    display: flex;
    flex-flow: row nowrap;
    padding: spacing(1.5) spacing(5);

    @media (nmcdbp(medium, down)) {
        padding: spacing(1.5) spacing(3);
    }
}

.dark-mode.footer-nmcd{
    background-color: $neutral-800;
    color: $neutral-100;
}

.copywrite-text {
    align-self: center;
    flex-grow: 1;
}

.footer-button-group {
    display: flex;
    flex-flow: row nowrap;

    & button, & a{
        margin-left: spacing(1);
    }
}