@import './Util/allUtils';

.award-card-list-container{
    margin-bottom: spacing(-3);
    & .award-container{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: spacing(2);

        & .award-company-logo{
            width: 56px;
            height: 56px;
            border-radius: 12px;
        }
    }
    & .award-card-cell {
        display: flex;
        align-items: stretch;
        margin-bottom: spacing(3);

        & .cardbase{
            width: 100%;
        }
    }
}

.dark-mode{
    & .award-card-list-container{
        & .award-container{
            & .award-company-logo{
                background-color: $neutral-100;
            }
        }
    }
}