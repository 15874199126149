@import './Util/allUtils';

.nmcd-page-container {
    width: calc(100% - spacing(10));
    padding-left: spacing(5);
    padding-right: spacing(5);
    @media (nmcdbp(medium, down)) {
      width: calc(100% - spacing(6));
      padding-left: spacing(3);
      padding-right: spacing(3);
    }
}

@mixin gridbase-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}
  
.nmcd-grid-row{
    @include gridbase-row;
    width: 100%;

    &-has-gutters{
        @include gridbase-row;
        width: calc(100% + spacing(3));
        margin-left: spacing(-1.5);
        margin-right: spacing(-1.5);
        
        @media (nmcdbp(small, only)) {
            width: calc(100% + spacing(2));
            margin-left: spacing(-1);
            margin-right: spacing(-1);
        }
    }
}
  
  .cell {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    min-height: 0px;
    min-width: 0px;
    width: 100%;
  }
  
  .align{
    &-middle{
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    &-center {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
  
  @for $i from 1 through 12 {
    .nmcd-grid-row > .small-#{$i} {
      @media (nmcdbp(small, up)) {
        width: calc(($i / 12) * 100%);
      }
    }
    .nmcd-grid-row-has-gutters > .small-#{$i} {
      @media (nmcdbp(medium, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
      @media (nmcdbp(small, only)) {
        width: calc((($i / 12) * 100%) - spacing(2));
        margin-left: spacing(1);
        margin-right: spacing(1);
      }
    }
  }
  @for $i from 1 through 12 {
    .nmcd-grid-row > .medium-#{$i} {
      @media (nmcdbp(medium, up)) {
        width: calc(($i / 12) * 100%);
      }
    }
    .nmcd-grid-row-has-gutters > .medium-#{$i} {
      @media (nmcdbp(medium, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
  }
  @for $i from 1 through 12 {
    .nmcd-grid-row > .large-#{$i} {
      @media (nmcdbp(large, up)) {
        width: calc(($i / 12) * 100%);
      }
    }
    .nmcd-grid-row-has-gutters > .large-#{$i} {
      @media (nmcdbp(large, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
  }
  @for $i from 1 through 12 {
    .nmcd-grid-row > .xlarge-#{$i} {
      @media (nmcdbp(xlarge, up)) {
        width: calc(($i / 12) * 100%);
      }
    }
    .nmcd-grid-row-has-gutters > .xlarge-#{$i} {
      @media (nmcdbp(xlarge, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
  }
  @for $i from 1 through 12 {
    .nmcd-grid-row > .xxlarge-#{$i} {
      @media (nmcdbp(xxlarge, up)) {
        width: calc(($i / 12) * 100%);
      }
    }
    .nmcd-grid-row-has-gutters > .xxlarge-#{$i} {
      @media (nmcdbp(xxlarge, up)) {
        width: calc((($i / 12) * 100%) - spacing(3));
        margin-left: spacing(1.5);
        margin-right: spacing(1.5);
      }
    }
  }

.nmcd-grid-row, .nmcd-grid-row-has-gutters{
    & > .hide-on-small{
        @media (nmcdbp(small, only)) {
            display: none !important;
        }
    }
    & > .hide-on-medium{
        @media (nmcdbp(medium, only)) {
            display: none !important;
        }
    }
    & > .hide-on-medium-down{
        @media (nmcdbp(medium, down)) {
            display: none !important;
        }
    }
    & > .hide-on-large-up{
        @media (nmcdbp(large, up)) {
            display: none !important;
        }
    }  
}