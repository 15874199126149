@import './Util/allUtils';

.enlarged-img{
    &-modal-content{
        height: 100%;

        @media (nmcdbp(medium, down)) {
            height: 100%;
            display: flex;
            flex-flow: column;
        }
    }

    &-body{
        @media (nmcdbp(medium, down)) {
            flex-grow: 1;
        }
    }

    &-container{
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-height: calc(100vh - 228px);
        flex-grow: 1;
        overflow: auto;

        @media (nmcdbp(medium, only)) {
            max-height: calc(100vh - 286px);
        }
        @media (nmcdbp(small, only)) {
            max-height: calc(100vh - 246px);
        }

        &-and-controls{
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
        }
    }

    max-width: 100%;
    transform-origin: top left;

    &.zoom-0{
        transform: scale(1);
    }
    &.zoom-1{
        transform: scale(1.25);
    }
    &.zoom-2{
        transform: scale(1.5);
    }
    &.zoom-3{
        transform: scale(1.75);
    }
    &.zoom-4{
        transform: scale(2);
    }
}

.action-row {
    padding-top: spacing(2);
    width: calc(100% + 24px);
    @include frosted-2;

    @media (nmcdbp(small, only)) {
        width: calc(100% + 16px);
    }

}

.dark-mode .action-row{
    @include frosted-2-dark;
}

.zoom-group{
    display: flex;
    justify-content: center;
    align-items: center;
    & p{
        padding: 0 spacing(3);

        @media (nmcdbp(small, only)) {
            display: none;
        }
    }

    @media (nmcdbp(medium, down)) {
        justify-content: flex-start;
    }

    & button:nth-child(1) {
        @media (nmcdbp(small, only)) {
            margin-right: spacing(1);
        }
    }
}

.images-group-container{
    height: calc(100vh - 162px);
    overflow-y: auto;
    overflow-x: hidden;
}

.pagination-group{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & p{
        padding: 0 spacing(3);

        @media (nmcdbp(small, only)) {
            display: none;
        }
    }

    & button:nth-child(1) {
        @media (nmcdbp(small, only)) {
            margin-right: spacing(1);
        }
    }
}