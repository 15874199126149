@import 'Util/allUtils';

.navitem {
    &-logo{
        width: 48px;
        height: 48px;
        position: relative;
        display: block;
        & > img {
            height: 48px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            transition: $standard-transition;

            &:not(:first-child) {
                opacity: 0;
            }
        }

        &:focus, &:focus-visible, &:hover {
            border: none;
            outline: none;
            & > img {
                opacity: 0;
                
                &:not(:first-child) {
                    opacity: 1;
                }
            }
        }
    }

    &-link, &-button {
        font-family: $body-font-family;
        font-size: fontsizing(18);
        font-weight: 400;
        line-height: fontsizing(24);
        color: $neutral-900;
        transition: $standard-transition;
        cursor: pointer;
        max-height: 48px;
        padding: spacing(1.5);
        text-decoration: none;
        background: none;
        border: none;
        position: relative;

        & > i {
            padding-left: spacing(1);
            font-size: fontsizing(16);
            font-weight: 400;
            line-height: fontsizing(16);
        }

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 2px;
            border-radius: 1px;
            background: $gradient-500;
            left: 50%;
            bottom: spacing(1.5);
            transition: $standard-transition;
        }

        &.active {
            &::after {
                content: '';
                position: absolute;
                width: calc(100% - spacing(3));
                height: 1px;
                border-radius: 1px;
                background: $neutral-900;
                left: spacing(1.5);
                bottom: spacing(1.5);
            }
        }

        &:focus, &:focus-visible, &:hover {
            border: none;
            outline: none;
            
            &::after {
                content: '';
                position: absolute;
                width: calc(100% - spacing(3));
                height: 2px;
                border-radius: 1px;
                background: $gradient-500;
                left: spacing(1.5);
                bottom: spacing(1.5);
            }
        }
    }
}

.dark-mode .navitem{
    &-link, &-button {
        color: $neutral-100;

        &::after {
            background: $gradient-500;
        }

        &.active {
            &::after {
                background: $neutral-100;
            }
        }
    }
}