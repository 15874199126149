@import './Util/allUtils';

.home-hero-block {
    position: relative;
    margin-top: spacing(25);
    min-height: calc(100vh - spacing(25));

    @media (nmcdbp(medium, only)) {
        margin-top: spacing(20);
        min-height: calc(100vh - spacing(20));
    }
    @media (nmcdbp(small, only)) {
        margin-top: spacing(15);
        //min-height: calc(100vh - spacing(15));
        min-height: auto;
    }
}

.home-hero-type-intro {
    display: block;
    font-weight: 400;
    font-size: fontsizing(40);
    line-height: fontsizing(48);
    color: $neutral-900;

    @media (nmcdbp(medium, only)) {
        font-size: fontsizing(32);
        line-height: fontsizing(38);
    }
    @media (nmcdbp(small, only)) {
        font-size: fontsizing(28);
        line-height: fontsizing(34);
    }
}
.home-hero-type-statement{
    padding-bottom: 8px;
    margin-bottom: -8px;
    font-weight: 600;
    font-size: fontsizing(56);
    line-height: fontsizing(62);
    color: $neutral-900;
    background: $gradient-500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba($neutral-900, .7);

    @media (nmcdbp(medium, only)) {
        font-size: fontsizing(48);
        line-height: fontsizing(56);
    }
    @media (nmcdbp(small, only)) {
        font-size: fontsizing(36);
        line-height: fontsizing(44);
    }
}

.home-hero-subhead {
    width: calc((5 / 7) * 100%);
    font-size: fontsizing(24);
    line-height: fontsizing(30);
    color: $neutral-900;

    @media (nmcdbp(medium, only)) {
        width: calc((7 / 10) * 100%);
        font-size: fontsizing(18);
        line-height: fontsizing(24);
    }
    @media (nmcdbp(small, only)) {
        width: 100%;
        font-size: fontsizing(18);
        line-height: fontsizing(24);
    }
}

.home-brain-small{
    @media (nmcdbp(medium, up)) {
        display: none;
    }
    width: 100%;
    padding-top: 40px;
    //margin-bottom: -25vh;

    & img {
        max-width: 100%;
        height: auto;
    }
}

.dark-mode{
    & .home-hero-type-intro, & .home-hero-subhead{
        color: $neutral-100;
    }
    & .home-hero-type-statement{
        color: $neutral-100;
        background: $gradient-500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba($neutral-100, .2);
    }
}