@import './Util/allUtils';

.form-error-message {
    padding: spacing(2);
    background-color: $red-100;
    border-radius: 16px;

    & a {
        color: $interactiveColor-700;
    }
}