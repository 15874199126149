$icomoon-font-family: "NMCD-Portfolio" !default;

$nmcd-Chevron: "\e900";
$nmcd-Close: "\e901";
$nmcd-Coding: "\e902";
$nmcd-Eye: "\e903";
$nmcd-LogoMark: "\e904";
$nmcd-Menu: "\e905";
$nmcd-Minus: "\e906";
$nmcd-Plus: "\e907";
$nmcd-Prototype: "\e908";
$nmcd-ScrollingPrt1: "\e909";
$nmcd-ScrollingPrt2: "\e90a";
$nmcd-Strategy: "\e90b";
$nmcd-UserResearch: "\e90c";
$nmcd-VisualDesign: "\e90d";
$nmcd-Wireframe: "\e90e";
$nmcd-HexImgFlag: "\e90f";
$nmcd-PaintImageFlag: "\e910";
$nmcd-HexImgFlagFF: "\e911";
$nmcd-PaintImageFlagFF: "\e912";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('../../Fonts/NMCD-Portfolio.eot');
    src: url('../../Fonts/NMCD-Portfolio.eot') format('embedded-opentype'), url('../../Fonts/NMCD-Portfolio.ttf') format('truetype'), url('../../Fonts/NMCD-Portfolio.woff') format('woff'), url('../../Fonts/NMCD-Portfolio.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

