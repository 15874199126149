@import './Util/allUtils';

.recommendation {
    &-card{
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        & .recommender{
            display: flex;

            & > img {
                height: 52px;
                margin-right: spacing(2);
                border-radius: 12px;
            }
        }
    }
}