@import './Util/allUtils';

.project-page-hero{
    margin-top: spacing(25);

    @media (nmcdbp(medium, only)) {
        margin-top: spacing(20);
    }

    @media (nmcdbp(small, only)) {
        margin-top: spacing(15);
    }

    & .page-title{
        margin-bottom: spacing(3);
    }
    & .page-subtitle{
        margin-bottom: spacing(5);
    }

    .hero-img-large {
        width: 100%;

        @media (nmcdbp(medium, down)) {
            display: none;
        }
    }
}

.project-page-info-card{

    &-container{

        & img{
            width: calc(100% - spacing(6));
            margin-bottom: spacing(-6);
            padding: 0 spacing(3);
            @media (nmcdbp(large, up)) {
                display: none;
            }
        }
    }

    &-content{
        display: grid;
        row-gap: spacing(1.5);

        @media (nmcdbp(medium, down)) {
            margin-top: spacing(3);
        }

        & .data-row{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;

            & .data-label {
                min-width: 100px;
                margin-right: spacing(1.5);
            }

            & .data-value{
                flex-grow: 1;
            }
        }
    }
}

ul.summary-list{
    list-style-type: none;
    margin-bottom: spacing(-1);
    
    & li{
        display: flex;
        align-items: flex-start;
        margin-bottom: spacing(1);
    
        &::marker {
        content: '';
        }
    
        &::before {
        content: '\F270';
        color: $neutral-900;
        font-family: "bootstrap-icons" !important;
        line-height: fontsizing(18);
        padding-top: spacing(.5);
        padding-left: spacing(1);
        padding-right: spacing(1);
        }
    }
}

.dark-mode ul.summary-list{
    & li{
        &::before {
        color: $neutral-100;
        }
    }
}

.project-page-content{
    position: relative;
}