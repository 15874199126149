/*#region Colors*/
$neutral-900: #0F1C0D;
$neutral-800: #273325;
$neutral-700: #4B5549;
$neutral-600: #687067;
$neutral-500: #878D86;
$neutral-400: #A6ABA5;
$neutral-300: #C3C6C2;
$neutral-200: #E7E8E7;
$neutral-100: #FDFDFD;

$interactiveColor-900: #1A3432;
$interactiveColor-800: #244B48;
$interactiveColor-700: #37716D;
$interactiveColor-600: #428682;
$interactiveColor-500: #499590;
$interactiveColor-400: #5FA29D;
$interactiveColor-300: #80B5B1;
$interactiveColor-200: #BFDAD8;
$interactiveColor-100: #DBEAE9;

$red-900: #4D0021;
$red-800: #800037;
$red-700: #BF0052;
$red-600: #E30062;
$red-500: #FF006E;
$red-400: #FF2684;
$red-300: #FF2684;
$red-200: #FFA6CC;
$red-100: #FFCCE2;

$orange-900: #4B1A02;
$orange-800: #7E2B03;
$orange-700: #C94506;
$orange-600: #E24D06;
$orange-500: #FB5607;
$orange-400: #FC6F2C;
$orange-300: #FC8951;
$orange-200: #FEC4A8;
$orange-100: #FEDDCD;

$yellow-900: #4D3903;
$yellow-800: #8C6806;
$yellow-700: #BF8E08;
$yellow-600: #E5AB0A;
$yellow-500: #FFBE0B;
$yellow-400: #FFC830;
$yellow-300: #FFD154;
$yellow-200: #FFE8AA;
$yellow-100: #FFF2CE;

$green-900: #192F15;
$green-800: #294F23;
$green-700: #427E39;
$green-600: #4B8E40;
$green-500: #539E47;
$green-400: #6DAD63;
$green-300: #87BB7E;
$green-200: #C3DDBF;
$green-100: #DDECDA;

$blue-900: #11284D;
$blue-800: #1D4380;
$blue-700: #2E6BCC;
$blue-600: #3479E5;
$blue-500: #3A86FF;
$blue-400: #5898FF;
$blue-300: #75AAFF;
$blue-200: #BAD5FF;
$blue-100: #D8E7FF;

$purple-900: #2D1A48;
$purple-800: #4B2B78;
$purple-700: #7141B3;
$purple-600: #884ED7;
$purple-500: #9757EF;
$purple-400: #A770F1;
$purple-300: #B689F4;
$purple-200: #DBC4F9;
$purple-100: #EADDFC;

$gradient-500: linear-gradient(135deg, $red-500 5%, $orange-500 23%, $yellow-500 41%, $green-500 59%, $blue-500 77%, $purple-500 95%);

@mixin frosted-1 {
    background-color: rgba($neutral-100, .8);
    backdrop-filter: blur(24px);
}

@mixin frosted-2 {
    background-color: rgba($neutral-100, .7);
    backdrop-filter: blur(16px);
}

@mixin frosted-3 {
    background-color: rgba($neutral-100, .45);
    backdrop-filter: blur(8px);
}

@mixin frosted-1-dark {
    background-color: rgba($neutral-900, .8);
    backdrop-filter: blur(24px);
}

@mixin frosted-2-dark {
    background-color: rgba($neutral-900, .7);
    backdrop-filter: blur(16px);
}

@mixin frosted-3-dark {
    background-color: rgba($neutral-900, .45);
    backdrop-filter: blur(8px);
}
/*#endregion*/

/*#region transitions*/
$standard-transition: all 200ms ease-out; 
$long-transition: all 400ms ease-out; 
/*#endregion*/


/*#region My Breakpoints and Function*/
$global-width: 100vw;

$bp-small: 0;
$bp-medium: 600px;
$bp-large: 900px;
$bp-xlarge: 1200px;
$bp-xxlarge: 1536px;

@function nmcdbp($bp, $direction){
    $result: '';
    @if $direction == 'only' {
        @if $bp == 'small' {
            $result: '(max-width:'+ $bp-medium + ')';
        } @else if $bp == 'medium' {
            $result: '(min-width:'+ $bp-medium + ') and (max-width: '+ $bp-large + ')';
        } @else if $bp == 'large' {
            $result: '(min-width:'+ $bp-large + ') and (max-width: '+ $bp-xlarge + ')';
        } @else if $bp == 'xlarge' {
            $result: '(min-width:'+ $bp-xlarge + ') and (max-width: '+ $bp-xxlarge + ')';
        } @else if $bp == 'xxlarge' {
            $result: '(min-width:'+ $bp-xxlarge + ')';
        }
    } @else if $direction == 'down' {
        @if $bp == 'small' {
            $result: '(max-width:'+ $bp-medium + ')';
        } @else if $bp == 'medium' {
            $result: '(max-width: '+ $bp-large + ')';
        } @else if $bp == 'large' {
            $result: '(max-width: '+ $bp-xlarge + ')';
        } @else if $bp == 'xlarge' {
            $result: '(max-width: '+ $bp-xxlarge + ')';
        } @else if $bp == 'xxlarge' {
            $result: '(min-width:'+ $bp-small + ')';
        }
    } @else if $direction == 'up' {
        @if $bp == 'small' {
            $result: '(min-width:'+ $bp-small + ')';
        } @else if $bp == 'medium' {
            $result: '(min-width:'+ $bp-medium + ')';
        } @else if $bp == 'large' {
            $result: '(min-width:'+ $bp-large + ')';
        } @else if $bp == 'xlarge' {
            $result: '(min-width:'+ $bp-xlarge + ')';
        } @else if $bp == 'xxlarge' {
            $result: '(min-width:'+ $bp-xxlarge + ')';
        }
    }
    @return $result;
}
/*#endregion*/

/*#region Spacing Variables*/
$spacingbase: 8;
@function spacing($spacingfactor) {
    $result: ($spacingfactor * $spacingbase) + px;
    @return $result;
}
/*#endregion*/

/*#region Font Variables*/
$body-font-family: 'Barlow', Helvetica, Roboto, Arial, sans-serif !default;
$heading-font-family: 'Barlow', Helvetica, Roboto, Arial, sans-serif !default;

$fontsizingbase: 16;
@function fontsizing($pxsize) {
    $result: ($pxsize/$fontsizingbase) + rem;
    @return $result;
}
/*#endregion*/